import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import { removeNumberPrefix } from '../../../../../utilities'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Kuupto'}
          description={'Prípadová štúdia'}
          text={'Nakupovanie v roku 2021 bude v znamení najmodernejších technológií vrátane Blockchainu a vlastných kryptomien. Tento progresívny e-shop smerujúci na globálny trh od nás dostal brand style, moderné logo, UX, webdesign a programovanie celého systému na mieru. Vidíme sa čoskoro online.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'Tvorba názvu' },
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/kuupto-branding' },
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php)', link: '/nase-prace/webstranky/eshopy/kuupto' },
          ]} />

        <AboutProject
          text={'Spolupráca s Kuupto prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Firemná identita'}
          description={'Pochopenie projektu, jeho zámeru a odborný prístup sú úspechom k plnohodnotnej firemnej identite.'}
          text={'Po konzultáciach s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal nový projekt prezentovať nie len designom, ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#2e3191', tintColorCode: '#585aa7', nameColor: '#FFFFFF', name: 'Bay of Many' },
          { code: '#eb1c24', tintColorCode: '#ef4950', nameColor: '#FFFFFF', name: 'Crimson' },
          { code: '#dcccca', tintColorCode: '#e3d5d3', nameColor: '#FFFFFF', name: 'Wafer' },
        ]} />

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Kuupto zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/eshopy/kuupto'}
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))} />
        
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        <MobileCarousel items={data.mobileGallery.nodes} />

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Mobilné telefóny'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/eshopy/mobilne-telefony/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/kuupto/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }

    landingImage: file(relativePath: { eq: "case-studies/kuupto/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/kuupto/logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/kuupto/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/kuupto/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
